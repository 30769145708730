import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import fetch from 'node-fetch'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import ReCAPTCHA from 'react-google-recaptcha'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'
import contactimg from '../assets/images/tradingsetup.jpg'

const BASE_API = process.env.BASE_API
const RECAPTCHA = process.env.RECAPTCHAKEY
const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class ContactPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,
      name: '',
      phone: '',
      email: '',
      message: '',
      submitted: false,
      recaptcha: '',
    }
    this.recaptchatoken = this.recaptchatoken.bind(this)
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = (event) => {
    event.preventDefault()

    if (this.state.recaptcha.length > 1) {
      let formData = {}
      formData['name'] = this.state.name
      formData['email'] = this.state.email
      formData['phone'] = this.state.phone
      formData['tok'] = this.state.recaptcha
      formData['subject'] = 'Contact Form'
      formData['desc'] = this.state.message

      let headers = new Headers()
      headers.append('Content-Type', 'text/html')
      headers.append('Origin', '*')
      var requestConfig = {
        method: 'POST',
        body: JSON.stringify(formData),
        mode: 'cors',
        cache: 'no-cache',
        headers: headers,
      }
      fetch(BASE_API + 'usermgmt_contactsubmit', requestConfig)
        .then((response) => response.json())
        .then((data) => {
          this.setState({ submitted: true })
          navigate('/thankyou')
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      navigate('/thankyou')
    }
  }
  recaptchatoken(token) {
    this.setState({ recaptcha: token })
  }

  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Contact Us'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)

    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    const contactlayout = (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <div style={{ marginTop: '5vmin' }}>
              <img className="contactfeature_img_right" src={contactimg} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="contact_form">
              <form
                id="contact-form"
                method="post"
                onSubmit={this.handleSubmit}
              >
                <Grid container spacing={0} className="form-group">
                  <Grid item xs={12}>
                    <h4>Name:</h4>
                    <input
                      type="text"
                      id="name-input"
                      placeholder="Name..."
                      className="contact_form_input"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="form-group">
                  <Grid item xs={12}>
                    <h4>Email:</h4>
                    <input
                      type="email"
                      id="email-input"
                      placeholder="Email..."
                      className="contact_form_input"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="form-group">
                  <Grid item xs={12}>
                    <h4>Message:</h4>
                    <textarea
                      id="description-input"
                      rows="2"
                      placeholder="Message…"
                      className="contact_form_message"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleInputChange}
                    ></textarea>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="form-group">
                  <Grid item xs={5}>
                    <button type="submit" className="contact_submit_button">
                      Submit
                    </button>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={5}>
                    <ReCAPTCHA
                      sitekey={RECAPTCHA}
                      onChange={(token) => this.recaptchatoken(token)}
                    />
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
    const contactlayoutMob = (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div style={{ marginTop: '5vmin' }}>
              <img className="contactfeature_img_right" src={contactimg} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="contact_form">
              <form
                id="contact-form"
                method="post"
                onSubmit={this.handleSubmit}
              >
                <Grid container spacing={0} className="form-group">
                  <Grid item xs={12}>
                    <h4>Name:</h4>
                    <input
                      type="text"
                      id="name-input"
                      placeholder="Name..."
                      className="contact_form_input"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="form-group">
                  <Grid item xs={12}>
                    <h4>Email:</h4>
                    <input
                      type="email"
                      id="email-input"
                      placeholder="Email..."
                      className="contact_form_input"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="form-group">
                  <Grid item xs={12}>
                    <h4>Message:</h4>
                    <textarea
                      id="description-input"
                      rows="2"
                      placeholder="Message…"
                      className="contact_form_message"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleInputChange}
                    ></textarea>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="form-group">
                  <Grid
                    item
                    xs={12}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '1vmin',
                    }}
                  >
                    <ReCAPTCHA
                      size="compact"
                      badge="bottomright"
                      sitekey={RECAPTCHA}
                      onChange={(token) => this.recaptchatoken(token)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <button type="submit" className="contact_submit_button">
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              {isMobile ? contactlayoutMob : contactlayout}
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(ContactPage)
export const ContactPageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
